import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { Box, Button, Grid, LinearProgress, Typography, linearProgressClasses, styled } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../../../config'
import {
  CancellationReasonInput,
  HostedLoginTokenDocument,
  ReasonEnum,
  RecurlySubscription,
  SubscriptionCancelDocument,
  SubscriptionDocument,
  SubscriptionReactivateDocument,
  UserProfile
} from '../../../../../graphql/generated'
import formatBytes from '../../../../../utils/formatBytes'
import logger from '../../../../../utils/logger'
import useGAEvent from '../../../../auth/hooks/useGAEvent'
import useAlerts from '../../../hooks/useAlerts'
import CancelModal from '../modals/CancelModal'
import CancelUpgradeModal from '../modals/CanelUpgradeModal'
import ConfirmCancelModal from '../modals/ConfirmCancelModal'
import DowngradeModal from '../modals/DowngradeModal'
import GetHelpModal from '../modals/GetHelpModal'
import UpgradeModal from '../modals/UpgradeModal'
import { CANCELPBCONTENT, CANCELPBLINK, CANCELPBTITLE, cancellationUrls, getPlanDisplayName } from '../utils/constants'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5B62FF'
  }
}))

interface Props {
  userProfile: UserProfile
  loading: boolean
  openChangePlan: () => void
  recurlySubscription?: RecurlySubscription
  refetch?: () => void
}

export default function PlanDetails({ userProfile, loading, openChangePlan, recurlySubscription }: Readonly<Props>) {
  const { createAlert } = useAlerts()
  const [fetchHostedLoginTokenAndRedirect] = useLazyQuery(HostedLoginTokenDocument, {
    onCompleted: (data) => {
      window.open(`${config.appUrl.recurly.editPayment}${data.subscriptions.hostedLoginToken}`, '_blank')
    }
  })
  const [subscriptionCancelMutation] = useMutation(SubscriptionCancelDocument)
  const [subscriptionReactivedMutation] = useMutation(SubscriptionReactivateDocument)
  const isCounterEnabled = false
  const totalSize = userProfile?.counters?.totalSize
  const storageUsed = totalSize ?? 0
  const storageAvailable = userProfile?.plan?.storage_limit ?? 0
  const percentageUsed = storageAvailable === -1 ? 0 : Math.min(100, (storageUsed / storageAvailable) * 100)
  const pendingPlan = getPlanDisplayName(recurlySubscription?.pendingChange?.plan?.code ?? 'no-plan')

  const vindiciaInMaintenanceMode = useFlag('vindiciaInMaintenanceMode')

  const trialPlan = userProfile?.subscription?.isTrial
  const trialExpirationDate = new Date(userProfile?.subscription?.trialExpDate ?? new Date())
  const formattedTrialExpirationDate = `${trialExpirationDate.getMonth() + 1}/${trialExpirationDate.getDate()}/${trialExpirationDate.getFullYear()}`
  const paymentMethod = userProfile.subscription?.subscription?.account ?? 'Free'
  const isApple = ['itunes', 'apple'].includes(paymentMethod.toLowerCase())
  const isPaypal = ['paypal', 'paypal_2', 'vindicia'].includes(paymentMethod.toLowerCase()) && !vindiciaInMaintenanceMode
  const isRecurly = ['recurly'].includes(paymentMethod.toLowerCase())

  const subscriptionId = userProfile?.subscription?.subscription?.id
  const subscription = useQuery(SubscriptionDocument, {
    skip: !subscriptionId && !isRecurly,
    variables: { subscriptionId: subscriptionId! }
  })

  const currentTermEndsAt = subscription.data?.subscriptions?.subscription?.recurlySubscription?.currentTermEndsAt
  const currentPlan = userProfile?.subscription?.planName?.split('-')[0] ?? 'Free'

  const vindiciaUnderMaintenance = vindiciaInMaintenanceMode && ['paypal', 'paypal_2'].includes(paymentMethod.toLowerCase())

  const expirationDate = new Date(userProfile?.subscription?.expirationDate ?? '')
  const hasExpired = expirationDate < new Date()
  const firstBillingDate = new Date(userProfile?.subscription?.firstBillingDate ?? '')
  const formattedExpirationDate = trialPlan
    ? `${expirationDate.getMonth() + 1}/${firstBillingDate.getDate()}/${firstBillingDate.getFullYear()}`
    : `${expirationDate.getMonth() + 1}/${expirationDate.getDate()}/${expirationDate.getFullYear()}`
  const storageUsedDisplay = formatBytes(storageUsed)
  let storageAvailableDisplay: JSX.Element | string
  const { trackEvent } = useGAEvent()

  switch (storageAvailable) {
    case -1:
      storageAvailableDisplay = <AllInclusiveIcon fontSize="medium" sx={{ ml: 1, mr: 1 }} />
      break
    case 0:
      storageAvailableDisplay = 'No Storage'
      break
    default:
      storageAvailableDisplay = formatBytes(storageAvailable)
      break
  }

  const isRecurring = userProfile?.subscription?.recurring

  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false)
  const [isDowngradeModalOpen, setIsDowngradeModalOpen] = useState<boolean>(false)
  const [isGetHelpModalOpen, setIsGetHelpModalOpen] = useState<boolean>(false)
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState<boolean>(false)
  const [isCancelOpen, setIsCancelOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>()
  const [paymentLink, setPaymentLink] = useState<string>()
  const [content, setContent] = useState<string>()
  const [supportUrl, setSupportUrl] = useState<string>()
  const [cancellationReason, setCancellationReason] = useState<CancellationReasonInput | undefined>()

  const newCancelModal = useFlag('newCancelModal')

  useEffect(() => {
    if (newCancelModal && cancellationReason) {
      if (cancellationReason.reason === ReasonEnum.HardToUse || cancellationReason.reason === ReasonEnum.Technical) {
        setIsGetHelpModalOpen(true)
      } else if (cancellationReason.reason === ReasonEnum.TooExpensive) {
        if (userProfile.plan?.is_storage_plan) {
          setIsConfirmCancelModalOpen(true)
        } else {
          setIsDowngradeModalOpen(true)
        }
      } else if (
        cancellationReason.reason === ReasonEnum.TemporaryUseCase ||
        cancellationReason.reason === ReasonEnum.Other ||
        cancellationReason.reason === ReasonEnum.DownloadContent ||
        cancellationReason.reason === ReasonEnum.MissingFeatures
      ) {
        if (userProfile.plan?.is_storage_plan) {
          setIsUpgradeModalOpen(true)
        } else {
          setIsDowngradeModalOpen(true)
        }
      }
    }
  }, [cancellationReason, newCancelModal, userProfile.plan?.is_storage_plan])

  const planName = getPlanDisplayName(userProfile?.plan?.plan_name ?? 'no-plan')
  const getStorageDisplay = () => {
    const storageLimit = userProfile.plan?.storage_limit

    if (storageLimit === -1) {
      return 'Unlimited Storage'
    }
    if (storageLimit !== null) {
      return `${formatBytes(storageLimit)} Compression Free Storage`
    }
    return 'Storage limit not set'
  }

  const onCancelModalClose = () => {
    setIsCancelModalOpen(false)
    setIsCancelOpen(false)
  }

  const onUpgradeModalClose = () => {
    setIsUpgradeModalOpen(false)
  }

  const onUpgradeModalContinue = () => {
    setIsUpgradeModalOpen(false)
    setTimeout(() => setIsConfirmCancelModalOpen(true), 1000)
  }

  const onDowngradeModalClose = () => {
    setIsDowngradeModalOpen(false)
  }

  const onDowngradeModalContinue = () => {
    setIsDowngradeModalOpen(false)
    setTimeout(() => setIsConfirmCancelModalOpen(true), 1000)
  }

  const onGetHelpModalClose = () => {
    setIsGetHelpModalOpen(false)
  }

  const onGetHelpModalContinue = () => {
    setIsGetHelpModalOpen(false)
    setTimeout(() => setIsConfirmCancelModalOpen(true), 1000)
  }

  const onConfirmCancelModalClose = () => {
    setIsConfirmCancelModalOpen(false)
  }

  const onReactivateSubscription = () => {
    if (subscriptionId) {
      const variables = {
        subscriptionId
      }
      subscriptionReactivedMutation({
        variables
      })
        .then(() => {
          createAlert('Your subscription has been reactivated successfully.', 'success')
        })
        .catch((error) => {
          createAlert('Failed to reactivate the subscription. Please try again.', 'error')
          logger.error('Error reactivating subscription:', error)
        })
    } else {
      createAlert('Subscription ID is missing. Unable to proceed with reactivation.', 'error')
    }
  }

  const onConfirmaCancelClick = () => {
    if (subscriptionId) {
      const variables = {
        subscriptionId,
        input: {
          ...cancellationReason,
          createdBy: userProfile?.id ?? 'unknown',
          reason: cancellationReason?.reason ?? ReasonEnum.Other
        }
      }

      subscriptionCancelMutation({
        variables
      })
        .then(() => {
          createAlert('Your subscription has been canceled successfully.', 'success')
          // window.location.reload()
        })
        .catch((error) => {
          createAlert('Failed to cancel the subscription. Please try again.', 'error')
          logger.error('Error canceling subscription:', error)
        })
    } else {
      createAlert('Subscription ID is missing. Unable to proceed with cancellation.', 'error')
    }
    setIsConfirmCancelModalOpen(false)
  }

  const handleUpdatePayment = async () => {
    if (!subscriptionId) {
      createAlert('There was an issue fetching your profile. Please try again later.', 'error')
      return
    }

    await fetchHostedLoginTokenAndRedirect({ variables: { subscriptionId } })
  }

  const handleUpdatePaypalPayment = () => {
    window.open(config.appUrl.paypal, '_blank')
  }

  // only for canceling recurly memberships
  const handleCancelPlanClick = () => {
    const planIdentifier = userProfile?.plan?.plan_name.toLowerCase()
    const redirectUrl = cancellationUrls[planIdentifier as keyof typeof cancellationUrls]

    if (redirectUrl) {
      if (newCancelModal) {
        setIsCancelOpen(true)
      } else {
        setIsCancelModalOpen(true)
        setTitle(CANCELPBTITLE.default)
        setContent(CANCELPBCONTENT.default)
        setPaymentLink(CANCELPBLINK)
      }

      setSupportUrl(redirectUrl)

      trackEvent('cancel_membership_clicked', {
        metric: 'cancel_membership_clicked',
        username: userProfile?.id,
        plan: userProfile?.plan?.plan_name,
        recurring: userProfile?.subscription?.recurring
      })
    } else {
      logger.error('No redirect URL found for the current plan:', planIdentifier)
    }
  }

  return (
    <>
      <div>
        <Typography variant="h6">{planName} Plan</Typography>
        {loading && <LinearProgress sx={{ mt: 3, maxWidth: '200px' }} />}
        {storageAvailable === -1 && <Typography style={{ marginTop: '10px' }}>Unlimited Storage</Typography>}
        {storageAvailable > 0 && isCounterEnabled && (
          <>
            <BorderLinearProgress sx={{ mt: 3, maxWidth: '200px' }} variant="determinate" value={percentageUsed} />
            <Typography sx={{ mt: 1, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              Storage: {storageUsedDisplay} / {storageAvailableDisplay}
            </Typography>
          </>
        )}
        {storageAvailable === 0 && <Typography sx={{ mt: 1, fontWeight: 600 }}>{storageAvailableDisplay}</Typography>}
        <Grid container style={{ margin: '20px 0' }}>
          <Grid item xs={12} md={4} lg={3}>
            <ul style={{ margin: 0 }}>
              {userProfile.plan?.plan_name !== 'no-plan' ? <li>{getStorageDisplay()}</li> : <li>You have no features associated with your plan.</li>}
              {userProfile.plan?.is_sharing_plan && <li>Unlimited Group & Personal Buckets</li>}
              {userProfile.plan?.plan_name !== 'no-plan' && !userProfile.plan?.is_sharing_plan && <li>Unlimited Personal Buckets</li>}
              {userProfile.plan?.is_modify_albums_enabled && <li>Advanced Album Organization</li>}
            </ul>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <ul style={{ margin: 0 }}>
              {userProfile.plan?.plan_name !== 'no-plan' && <li>Free Facebook Integration</li>}
              {userProfile.plan?.is_image_editor_enabled && <li>Photo Image Editing</li>}
              {userProfile.plan?.is_video_player_enabled && <li>Video Playback</li>}
            </ul>
          </Grid>
        </Grid>
        {!trialPlan && (
          <Typography>
            <span style={{ color: '#666' }}>{isRecurring ? 'Renews on ' : 'Your current plans expires on '}</span>
            <strong>{formattedExpirationDate}</strong>
            {pendingPlan !== 'No Plan' ? (
              <>
                {' '}
                as the <strong>{pendingPlan}</strong>.
              </>
            ) : (
              '.'
            )}
          </Typography>
        )}
        {trialPlan && (
          <Typography>
            Trial ends on <strong>{formattedTrialExpirationDate}</strong>.
            <br />
            <span style={{ color: '#666' }}>{isRecurring ? 'Renews on' : 'Your current plans expires on '}</span>{' '}
            <strong>{formattedTrialExpirationDate}</strong>.
          </Typography>
        )}
        {vindiciaUnderMaintenance && (
          <Grid alignItems="center">
            <Typography style={{ paddingTop: 10, color: '#880808', maxWidth: 700 }}>
              We are currently performing maintenance on our payment system. Please check back later, or with the{' '}
              <Link
                to="https://support.photobucket.com/hc/en-us"
                target="_blank"
                style={{
                  color: '#0056D2',
                  fontWeight: '600',
                  textDecoration: 'none'
                }}
              >
                Member Engagement Team
              </Link>{' '}
              to update your payment method or cancel your subscription.
            </Typography>
          </Grid>
        )}
        {isPaypal && (
          <Grid alignItems="center">
            <Button onClick={handleUpdatePaypalPayment} sx={{ display: 'block', mb: 2, p: 0 }} variant="text">
              Visit PayPal.com to view or update your payment method
            </Button>
          </Grid>
        )}
        {isApple && (
          <Typography style={{ paddingTop: 10 }}>
            Your subscription was started via Apple. To update your status or payment method, visit your iCloud subscriptions on your Apple device
          </Typography>
        )}
        {isRecurly && (
          <>
            <Button data-testid="view-or-update-payment-method" onClick={handleUpdatePayment} sx={{ display: 'block', mb: 2, p: 0 }} variant="text">
              View or update your payment method
            </Button>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
              {isRecurring && (
                <Button
                  color="error"
                  variant="outlined"
                  data-testid="cancel-membership"
                  sx={{ width: 'fit-content' }}
                  onClick={handleCancelPlanClick}
                  disabled={Boolean(userProfile?.subscription?.isCanceled)}
                  data-pendo="cancel-membership--step-01"
                >
                  Cancel Membership
                </Button>
              )}
              {!isRecurring && !hasExpired && (
                <Button data-testid="upgrade-or-change-plan" onClick={onReactivateSubscription} variant="contained" sx={{ width: 'fit-content' }}>
                  Reactivate {planName} Plan
                </Button>
              )}
              {!userProfile?.subscription?.isCanceled && (
                <Button
                  data-testid="upgrade-or-change-plan"
                  onClick={openChangePlan}
                  variant="contained"
                  sx={{ width: 'fit-content' }}
                  data-pendo="upgrade-membership--step-01"
                >
                  Upgrade or Change Plan
                </Button>
              )}
              {userProfile?.subscription?.isCanceled && (
                <Button onClick={openChangePlan} variant="contained" sx={{ width: 'fit-content' }} data-pendo="resubscribe-membership--step-01">
                  Resubscribe
                </Button>
              )}
            </Box>
          </>
        )}
      </div>
      <CancelUpgradeModal
        isOpen={isCancelModalOpen}
        onClose={onCancelModalClose}
        title={title}
        content={content}
        link={paymentLink}
        upgradeLink={openChangePlan}
        url={supportUrl}
        userProfile={userProfile}
      />
      <CancelModal isOpen={isCancelOpen} onClose={onCancelModalClose} userProfile={userProfile} setCancellationReason={setCancellationReason} />
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={onUpgradeModalClose}
        onContinue={onUpgradeModalContinue}
        currentTermEndsAt={currentTermEndsAt as string}
        createAlert={createAlert}
      />
      <DowngradeModal
        isOpen={isDowngradeModalOpen}
        onClose={onDowngradeModalClose}
        onContinue={onDowngradeModalContinue}
        currentPlanName={currentPlan}
        currentTermEndsAt={currentTermEndsAt as string}
        createAlert={createAlert}
      />
      <GetHelpModal
        isOpen={isGetHelpModalOpen}
        onClose={onGetHelpModalClose}
        onContinue={onGetHelpModalContinue}
        currentPlanName={currentPlan}
        currentTermEndsAt={currentTermEndsAt as string}
      />
      <ConfirmCancelModal isOpen={isConfirmCancelModalOpen} onClose={onConfirmCancelModalClose} confirmCancellation={onConfirmaCancelClick} />
    </>
  )
}
