import CloseIcon from '@mui/icons-material/Close'
import { AlertColor, Box, Button, DialogContent, IconButton, Typography, styled, useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { getAuth } from 'firebase/auth'
import { GraphQLError } from 'graphql'
import theme from '../../../../../config/theme'
import { UserProfileFragmentFragmentDoc } from '../../../../../graphql/generated'
import cache from '../../../../../providers/apollo/cache'
import useSubscriptionUpdate from '../../../../subscription/hooks/useSubscriptionUpate'

export const StyledButton = styled(Button)(() => ({
  background: 'linear-gradient(158.55deg, #202CD3 5.3%, #02E39F 102.34%)',
  borderRadius: '75px',
  border: 'none',
  color: '#fff',
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 600,
  padding: '10px 30px',
  '&:hover': {
    background: 'linear-gradient(158.55deg, #02E39F 5.3%, #202CD3 102.34%)',
    border: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem'
  }
})) as typeof Button

interface UpgradeModalProps {
  isOpen: boolean
  onClose: () => void
  onContinue: () => void
  currentTermEndsAt: string
  createAlert: (originalMessage: string, severity?: AlertColor, graphQLError?: GraphQLError | null) => void
}

function UpgradeModal({ isOpen, onClose, onContinue, currentTermEndsAt, createAlert }: Readonly<UpgradeModalProps>) {
  //   const { createAlert } = useAlerts()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { updateSubscription } = useSubscriptionUpdate()

  const formattedDate = currentTermEndsAt
    ? new Date(currentTermEndsAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    : 'date not available'

  const handleChangePlan = () => {
    const userId = getAuth().currentUser?.uid
    if (!userId) {
      createAlert('There was an issue with your session. Please refresh the page and try again.', 'error')
    }
    const profile = cache.readFragment({
      id: `UserProfile:${userId}`,
      fragmentName: 'UserProfileFragment',
      fragment: UserProfileFragmentFragmentDoc
    })

    const subscriptionId = profile?.subscription?.id
    if (!subscriptionId) {
      createAlert('There was an issue fetching your subscription. Please try again later.', 'error')
      return
    }

    const planCode = 'sharing-monthly'

    updateSubscription(subscriptionId, { planCode }, (apolloError) => {
      createAlert(apolloError.message, 'error')
    }).then(() => {
      createAlert('Subscription updated successfully', 'success')
    })

    onClose()
  }

  return (
    <Dialog data-test="upgrade-modal" open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle sx={{ textAlign: 'left', paddingBottom: '8px', position: 'relative' }}>
        <Typography color="primary" sx={{ fontWeight: '600', fontSize: '20px' }}>
          Want to try a plan with more features?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (icontheme) => icontheme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px', textAlign: 'center' }}>
        <Box sx={{ marginBottom: '16px' }}>
          <img
            src="https://content.photobucket.com/img/group-bucket/create-bucket.png"
            alt="Create Icon"
            style={{ width: isMobile ? '50px' : '100px', height: isMobile ? '50px' : '100px' }}
          />
        </Box>
        <Typography sx={{ marginBottom: '16px', fontSize: '14px', lineHeight: '20px' }}>
          Not getting enough out of your My Bucket Plan? Upgrade to Group Buckets Plan. With Group Buckets, you will be gaining access to essential
          features like <span style={{ fontWeight: 'bold', color: 'primary' }}>sharing, photo editing and video playback.</span>
        </Typography>
        <Box
          data-testid="plan-details-box"
          sx={{
            width: '100%',
            padding: '16px',
            textAlign: 'left',
            border: '1px solid #D0D5DD',
            borderRadius: '8px',
            marginBottom: '24px'
          }}
        >
          <Typography variant="subtitle1" data-testid="plan-change-title" sx={{ marginBottom: '4px', fontWeight: '600' }}>
            Group Buckets Plan, Monthly
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '4px', fontWeight: '500' }}>
            $8.00/month
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px' }}>
            The prorated blance will be billed today. Your first full payment of $8.00 will be on {formattedDate} and billing will recur on a monthly
            basis. The payment method of your current subscription will be used. You can cancel anytime.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: isMobile ? '' : '5px' }}>
          <Button
            sx={{ minWidth: '120px', fontWeight: '600', padding: '8px 16px', fontSize: isMobile ? '0.7rem' : '0.875rem' }}
            color="secondary"
            onClick={onContinue}
          >
            Continue to Cancel
          </Button>
          <StyledButton
            data-testid="agree-and-change-plan"
            sx={{ minWidth: '40px', fontWeight: '600', padding: '8px 16px' }}
            color="error"
            onClick={handleChangePlan}
          >
            Agree & Change Plan
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UpgradeModal
