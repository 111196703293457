import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, DialogContent, IconButton, Typography, styled, useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import theme from '../../../../../config/theme'

export const StyledButton = styled(Button)(() => ({
  background: 'linear-gradient(158.55deg, #202CD3 5.3%, #02E39F 102.34%)',
  borderRadius: '75px',
  border: 'none',
  color: '#fff',
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 600,
  padding: '10px 30px',
  '&:hover': {
    background: 'linear-gradient(158.55deg, #02E39F 5.3%, #202CD3 102.34%)',
    border: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem'
  }
})) as typeof Button

interface ConfirmCancelModalProps {
  isOpen: boolean
  onClose: () => void
  confirmCancellation: () => void
}

function ConfirmCancelModal({ isOpen, onClose, confirmCancellation }: Readonly<ConfirmCancelModalProps>) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog data-test="get-help-modal" open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle sx={{ textAlign: 'left', paddingBottom: '8px', position: 'relative' }}>
        <Typography color="primary" sx={{ fontWeight: '600', fontSize: '20px' }}>
          Confirm Cancellation
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (icontheme) => icontheme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px', textAlign: 'center' }}>
        <Box sx={{ marginBottom: '16px' }}>
          <img
            src="https://content.photobucket.com/img/subscription/gradient_warning_icon.png"
            alt="Warning Icon"
            style={{ width: isMobile ? '50px' : '100px', height: isMobile ? '50px' : '100px' }}
          />
        </Box>
        <Typography sx={{ marginBottom: '16px', fontSize: '14px', lineHeight: '20px' }}>
          Once you cancel and your subscription ends, your account will be deactiveated. You will no longer be able to view or download your
          account&apos;s vidoes and photos.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: isMobile ? '' : '5px' }}>
          <Button
            data-testid="confirm-cancellation"
            sx={{ minWidth: '120px', fontWeight: '600', padding: '8px 16px', fontSize: isMobile ? '0.7rem' : '0.875rem' }}
            color="secondary"
            onClick={confirmCancellation}
          >
            Confirm Cancellation
          </Button>
          <StyledButton
            data-testid="contact-support-button"
            sx={{ minWidth: '40px', fontWeight: '600', padding: '8px 16px' }}
            color="error"
            onClick={onClose}
          >
            Keep Subscription
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmCancelModal
