import { Close as CloseIcon } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useEffect, useState } from 'react'
import { CancellationReasonInput, ReasonEnum, UserProfile } from '../../../../../graphql/generated'

interface CancelModalProps {
  isOpen: boolean
  onClose: () => void
  userProfile: UserProfile
  setCancellationReason: React.Dispatch<React.SetStateAction<CancellationReasonInput | undefined>>
}

export default function CancelModal({ isOpen, onClose, userProfile, setCancellationReason }: Readonly<CancelModalProps>) {
  const reasonMapping: Record<string, ReasonEnum> = {
    'Hard to use': ReasonEnum.HardToUse,
    'I only needed Photobucket for a short-term use or event': ReasonEnum.TemporaryUseCase,
    'I only wanted to download my videos and photos': ReasonEnum.DownloadContent,
    'Missing features': ReasonEnum.MissingFeatures,
    'Price is too high': ReasonEnum.TooExpensive,
    'I’m having technical issues': ReasonEnum.Technical,
    'Other / Rather not say': ReasonEnum.Other
  }

  const reverseReasonMapping = Object.entries(reasonMapping).reduce(
    (acc, [text, enumValue]) => ({ ...acc, [enumValue]: text }),
    {} as Record<ReasonEnum, string>
  )

  const [reason, setReason] = useState<ReasonEnum | ''>('')
  const [comment, setComment] = useState('')
  const [contactAllowed, setContactAllowed] = useState(true)
  const totalMedia = userProfile.counters?.totalMedia ?? 0

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // reset reason when modal is closed or opened
  useEffect(() => {
    setReason('')
  }, [isOpen])

  const handleCommentChange = (e: { target: { value: string } }) => {
    if (e.target.value.length <= 1000) {
      setComment(e.target.value)
    }
  }

  const handleContinueToCancel = async () => {
    setCancellationReason((prev) => ({
      ...prev,
      createdBy: userProfile.id,
      comment,
      reason: reason as ReasonEnum,
      isAllowCommunication: contactAllowed
    }))
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: isMobile ? '1.25rem' : '1.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        We’re sorry to see you go. How did we fall short?
        <IconButton onClick={onClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ fontSize: isMobile ? '1.25rem' : '1.5rem' }}>
        <Typography variant="body2" gutterBottom sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
          Members like you are what make Photobucket amazing. Canceling your subscription will result in losing access to {totalMedia} videos and
          photos and premium features. Please take a minute to help us improve or
          <a href="https://support.photobucket.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
            {' '}
            contact our member engagement team{' '}
          </a>
          for help.
        </Typography>
        <RadioGroup
          data-testid="reason-radio-group"
          value={reason ? reverseReasonMapping[reason] : ''}
          onChange={(e) => setReason(reasonMapping[e.target.value])}
          sx={{ gap: isMobile ? '0px' : '8px' }}
        >
          {Object.keys(reasonMapping).map((option, index) => (
            <FormControlLabel
              key={option}
              value={option} // Use display text as the value
              control={<Radio data-testid={`reason-radio-${index}`} sx={{ transform: isMobile ? 'scale(0.8)' : 'scale(1)' }} />}
              label={<Typography sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>{option}</Typography>}
            />
          ))}
        </RadioGroup>
        <TextField
          label="Leave a comment"
          fullWidth
          multiline
          rows={3}
          value={comment}
          onChange={handleCommentChange}
          sx={{ my: 2, '& .MuiInputBase-input': { fontSize: isMobile ? '0.875rem' : '1rem' } }}
        />

        <FormControlLabel
          sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}
          control={
            <Checkbox
              sx={{ transform: isMobile ? 'scale(0.8)' : 'scale(1)' }}
              checked={contactAllowed}
              onChange={(e) => setContactAllowed(e.target.checked)}
            />
          }
          label={<Typography sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>Allow a Photobucket team member to contact me</Typography>}
        />
      </DialogContent>

      <DialogActions>
        <Button data-testid="continue-cancellation" onClick={handleContinueToCancel} disabled={!reason}>
          Continue to Cancel
        </Button>
        <Button onClick={onClose} variant="contained" color="primary">
          Keep Subscription
        </Button>
      </DialogActions>
    </Dialog>
  )
}
